import * as React from "react"

import Layout from "../layouts/layout"

import "./404.scss"

const NotFoundPage = () => (
  <Layout
    meta={{
      title: "404",
    }}
  >
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
